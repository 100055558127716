// react
import React from "react";
// local files, functions, etc...
import Layout from "../components/Layout/layout";
// comps
import Banner from "./common/Banner";
import ContactForm from "./contato/ContactForm";
import SideBar from "./contato/SideBar";

const ContactPage = () => {
    return (
        <Layout infoPage={{page: "contato"}}>
            <div className="contato">
                <Banner text="Contato" subtitle="Informações | Pedidos" />
                <main className="main-section container-60 margin-vertical-100px">                    
                    <ContactForm />                    
                    <SideBar />
                </main>                            
            </div>
        </Layout>
    )
}
export default ContactPage;